@import 'variables';
@import 'mixins';

.kyc {
}
.mat-typography {
  h1 {
    @include media('<maxPhone') {
      font-size: 36px;
    }
  }
  h2 {
    @include media('<maxPhone') {
      font-size: 32px;
    }
  }
  h3 {
    @include media('<maxPhone') {
      font-size: 24px;
    }
  }
}

.wave-bg {
  @include wave;
}

.wave-img {
  @include wave-img;
}

