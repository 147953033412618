@use '@angular/material' as mat;
@import '../themes/_pallete';

$primary-400: mat.get-color-from-palette($kyc-primary-pallete, 400);
$primary: mat.get-color-from-palette($kyc-primary-pallete, 500);
$primary-600: mat.get-color-from-palette($kyc-primary-pallete, 600);
$primary-700: mat.get-color-from-palette($kyc-primary-pallete, 700);
$primary-800: mat.get-color-from-palette($kyc-primary-pallete, 800);
$primary-900: mat.get-color-from-palette($kyc-primary-pallete, 900);
$warn-400: mat.get-color-from-palette($kyc-danger-pallete, 400);
$warn: mat.get-color-from-palette($kyc-danger-pallete, 500);
$accent-200: mat.get-color-from-palette($kyc-secondary-pallete, 200);
$accent-300: mat.get-color-from-palette($kyc-secondary-pallete, 300);
$accent-400: mat.get-color-from-palette($kyc-secondary-pallete, 400);
$accent: mat.get-color-from-palette($kyc-secondary-pallete, 500);

/***************************************************/

$black: #000;
$ebonyClay: #293241;
$mirage: #1c2535;
$cornflower-blue: #6366f1;
$scienceBlue: #0174df;

$royalBlue: #5138ee;
$turquoiseBlue: #5ce7c4;
$gray: #808080;
$geyser: #d8dde3;
$mystic: #d7dfe8;
$azureRadiance: #0399ff;
$flamenco: #ff810d;
$alizarinCrimson: #eb263e;

$cerulean: #00a8e7;
$cerulean: #00a8e7;
$tropicalBlue: #c2dcf8;
$linkWater: #c6dbf2;
$solitude: #e2f0fe;
$gallery: #eeeeee;
$alabaster: #f8f8f8;
$white: #fff;

$card-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.2), 0 10px 8px 0 rgba(0, 0, 0, 0.05);
$link-shadow: 0 10px 8px rgba(0, 0, 0, 0.05), 0 4px 15px rgba(0, 0, 0, 0.2);
