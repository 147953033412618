@import 'variables/color';

.kyc {
  .cdk-overlay-container {
    .main-menu__backdrop + .cdk-overlay-connected-position-bounding-box {
      top: 60px !important;
      width: 100% !important;
      .cdk-overlay-pane {
        width: 100%;
        top: 0 !important;

        .main-menu {
          width: 100%;
          max-width: unset;
          background: linear-gradient(90deg, #0e1c33 0.52%, $royalBlue 100%);
          border-bottom: 1px solid rgba($turquoiseBlue, 0.3);
          border-radius: unset;
        }
      }
    }
  }

  .main-menu {
    &__backdrop {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
}
