@mixin block-alignment {
  padding-top: 80px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
}

@mixin wave {
  background: {
    image: url('/assets/images/wave.svg');
    position: bottom;
    size: contain;
    repeat: no-repeat;
  }
}

@mixin wave-img {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
