.kyc {
  .mat-snack-bar-container {
    background: $white;
    border-radius: 6px;
    overflow: hidden;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 4px;
      background: currentColor;
    }
    &.snack-success {
      color: $cerulean;
    }
    &.snack-info {
      color: $cerulean;
    }
    &.snack-error {
      color: $cerulean;
    }
  }
}
