.kyc {
  .mat-form-field-appearance-outline {
    .mat-form-field-infix {
      padding: 16px 0 12px 0;
      border-top: 0;
    }
  }

  .mat-checkbox {
    .mat-checkbox-layout {
      white-space: unset;
      align-items: flex-start;
      .mat-checkbox-inner-container {
        width: 20px;
        height: 20px;
        margin: unset;
        margin-right: 18px;
      }
      .mat-checkbox-label {
        font-size: 16px;
      }
    }
  }
}
