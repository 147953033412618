@import 'variables/color';

.kyc {
  .mobile-nav {
    .mat-expansion-panel {
      background-color: unset;
      color: $white;

      &-header {
        font-size: 16px;
        padding: 0 16px;

        .mat-expansion-indicator::after {
          color: $white;
        }
      }
    }
  }
  .faq {
    .mat-expansion-panel {
      margin-bottom: 16px;
      border-radius: 10px !important;

      .mat-expansion-panel-header {
        height: unset;
      }
    }
  }
  .stepper {
    .solution-accordion {
      .mat-expansion-panel {
        margin-bottom: 16px;
        border-radius: 10px !important;
        &.mat-expanded {
          background: linear-gradient(180deg, #e1f3ff 0%, #ebfbfa 100%);
        }

        .mat-expansion-panel-header {
          height: unset;
          font-size: 20px;
          font-weight: 700;
          padding: 18px;
        }
      }
    }
  }
}
