@import 'variables';

.no-pointer-events {
  pointer-events: none;
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

.overflow-wrap-break-word {
  overflow-wrap: anywhere;
}

.white-space-normal {
  white-space: normal;
}

.white-space-no-wrap {
  white-space: nowrap;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

.white-space-pre-line {
  white-space: pre-line;
}

.uppercase {
  text-transform: uppercase;
}

.truncate,
.truncate-child > * {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.truncate-child {
  overflow: hidden;
}

.line-height-normal {
  line-height: normal;
}

.w100 {
  width: 100%;
}

.w75 {
  width: 75%;
}

.w25 {
  width: 25%;
}

.w50 {
  width: 50%;
}

.w33 {
  width: 33%;
}

.h100 {
  height: 100%;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.separator {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.pt-50 {
  padding-top: 50px;
}

.pt-20 {
  padding-top: 20px;
}

.fs-inherit {
  font-size: inherit !important;
}
.fs-20 {
  font-size: 20px;
}

.text-bold {
  font-weight: 700 !important;
}

.text-normal {
  font-weight: 400 !important;
}

.text-center {
  text-align: center !important;
}

.d-block {
  display: block;
}

.d-f {
  display: flex;
}

.opacity-6 {
  opacity: 0.6;
}

.cursor-pointer {
  cursor: pointer;
}

.back__link {
  display: inline-flex;
  align-items: center;
  margin-bottom: 20px;
}

@mixin max-lines($number, $line-height) {
  height: #{$number * $line-height}em;
  display: -webkit-box;
  -webkit-line-clamp: $number;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.m-l_8 {
  margin-left: 8px;
}

.m-r_8 {
  margin-right: 8px;
}

.m-b_24 {
  margin-bottom: 24px;
}

.min-h_100 {
  min-height: 100%;
}
