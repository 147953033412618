@import 'variables/color';

.kyc {
  .slick-slider {
    .card {
      margin: 0 15px;
    }
    .slick-list {
      height: 100%;
      .slick-track {
        height: 100%;
        padding: 15px 0;
      }
    }

    .slick-next {
      right: -35px;

      &:before {
        content: 'arrow_forward_ios';
      }
    }

    .slick-prev {
      left: -30px;

      &:before {
        content: 'arrow_back_ios';
      }
    }

    .slick-prev,
    .slick-next {
      z-index: 1;

      &:before {
        font-family: 'Material Icons';
        color: $ebonyClay;
      }
    }
    &.round-arrows {
      .slick-arrow {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        border: 2px solid $royalBlue;
        &:before {
          font-size: 14px;
          color: $royalBlue;
        }
        &.slick-prev:before {
          position: relative;
          left: 2px;
        }
      }
    }
    &.feedback__slider {
      .slick-track {
        display: flex;
      }
    }
  }
}
