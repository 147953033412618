@use 'media';
@use '../variables/spacing';
@use '../variables/sizes';

.kyc {
  .container {
    padding: 0 10px;
    max-width: 1240px;
    margin: 0 auto;
    box-sizing: border-box;

    @include media.max-media-query(sizes.$ww-1279) {
      max-width: 1024px;
    }
    @include media.max-media-query(sizes.$ww-1023) {
      max-width: 768px;
    }
    @include media.max-media-query(sizes.$ww-767) {
      padding: 0 15px;
    }

    &-sm {
      padding: spacing.$spacing-4;
    }
  }
}
